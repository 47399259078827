<template>
  <div class="job-list">
    <ul class="flex uls" v-loading="loading" element-loading-text="拼命加载中">
      <li class="lis" v-for="item in list" :key="item.id">
        <div class="wrap">
          <div class="box flex">
            <div class="flexs">
              <div class="doc">
                <h3 class="line_clamp1">
                  <router-link
                    :to="
                      '/public/recommendJob/?id=' +
                      item.id +
                      '&inpo=' +
                      item.inpo
                    "
                    class=""
                    target="_blank"
                    >每周职位推荐第{{ item.inpo }}期
                  </router-link>
                  <span v-if="item.status">进行中</span>
                </h3>
                <p class="line_clamp1">
                  共{{ item.jobTotal }}个职位 &nbsp;&nbsp;&nbsp;&nbsp;{{
                    item.companyTotal
                  }}个企业
                </p>
              </div>
            </div>
            <div>
              <p class="more cursorP">
                <router-link
                  :to="
                    '/public/recommendJob/?id=' + item.id + '&inpo=' + item.inpo
                  "
                  class=""
                  target="_blank"
                >
                </router-link>
              </p>
            </div>
          </div>
          <div class="box_2 flex">
            <ul class="u1">
              <li
                class="line_clamp1"
                v-for="job in item.jobDTOList"
                :key="job.id"
              >
                <router-link
                  :to="'/job/jobDetail/?id=' + job.id"
                  target="_blank"
                  :title="job.jobName"
                  >{{ job.jobName }}</router-link
                >
              </li>
            </ul>
            <ul class="u2">
              <li v-for="job in item.jobDTOList" :key="job.id">
                {{ job.jobSalary }}
              </li>
            </ul>
          </div>
          <div class="box_3 flexs">
            <button
              type="button"
              class="el-button el-button--primary is-round"
              @click="toAllJob(item)"
            >
              <span>查看全部职位 </span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <status :type="type" v-if="isHide"></status>

    <div class="pagination flexs">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        class="work_pagination"
        :current-page.sync="params.pageNum"
        :page-size="params.pageSize"
        layout="prev, pager, next, total, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import status from "@/components/public/status";

export default {
  data() {
    return {
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 9,
      },
      loading: false,
      list: [],
      isHide: "",
      type: "",
    };
  },
  components: {
    status,
  },
  methods: {
    toAllJob(item) {
      this.$router.push({
        path: "/public/recommendJob",
        query: {
          id: item.id,
          inpo: item.inpo,
        },
      });
    },
    handleSizeChange() {},
    handleCurrentChange(page) {
      this.params.pageNum = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .post("/api/app-jycy-job/getJobTuiweek", this.params)
        .then((res) => {
          res = res.data;
          if (res.success) {
            const { records, total } = res.data;
            this.list = records;
            this.total = total;
            if (total <= 0) {
              this.isHide = true;
              this.type = "result";
            } else {
              this.isHide = false;
            }
          } else {
            this.$message.error(res.msg);
            this.isHide = true;
            this.type = "wifi";
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.isHide = true;
          this.type = "wifi";
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang='less' scoped>
.job-list {
  width: 1200px;
  margin: 20px auto 0;
}
.pagination {
  margin-bottom: 20px;
}
.uls {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 450px;
  margin-bottom: 20px;
  .lis {
    margin-bottom: 20px;
    border-radius: 6px;
    transition: all 0.6s;
    &:not(:nth-of-type(3n)) {
      margin-right: 21px;
    }
    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
    }
    .wrap {
      padding: 20px;
      box-sizing: border-box;
      width: 386px;
      min-height: 275px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;

      .box {
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cccccc;
        width: 100%;
        padding-bottom: 15px;
        .flexs {
          justify-content: flex-start;
        }
        .imgs {
          width: 60px;
          height: 60px;
          border: 1px solid #e1e1e1;
          // overflow: hidden;
          margin-right: 15px;
          border-radius: 4px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 4px;
          }
        }

        .doc {
          h3 {
            font-size: 18px;
            color: #333333;
            margin-bottom: 10px;
            span {
              font-weight: normal;
              font-size: 14px;
              color: #ff8040;
            }
          }

          p {
            color: #666666;
            font-size: 14px;
            width: 90%;
          }
        }

        .more {
          width: 18px;
          height: 18px;
          background: url("~static/onlieJ/videoJob/more.png") center center
            no-repeat;
          background-size: 18px 18px;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .box_2 {
        flex: 1;
        margin-top: 17px;
        height: 100%;

        .u1 {
          flex: 1;

          li {
            font-size: 16px;
            color: #333333;
            line-height: 30px;
            flex: 1;
            overflow: hidden;
            padding-right: 20px;
          }
        }

        .u2 {
          li {
            color: #ff8040;
            font-size: 14px;
            line-height: 30px;
          }
        }
      }

      .box_3 {
        width: 100%;
        height: 36px;
        margin-top: 22px;
      }
    }
  }
}
</style>